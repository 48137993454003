export const ROUTES = {
  maintenance: "/maintenance",
  home: "/",
  user: "/user/",
  newsList: "/news",
  newsArticleView: "/news/:newsArticleId",
  registration: "/registration",
  singleSignOn: "/single-sign-on",
  viisp: "/viisp",
  events: "events",
  eventView: "event/:id",
  externalEvaluationFormAnswer: {
    main: "evaluation-questionnaire-user-record/:publicId",
    answered: "evaluation-questionnaire-user-record/:publicId/answered",
  },
  passwordChange: "/change-password/:token",
  registerInstitutionRepresentative: "register-institution-representative",
  confirmationWindow: "confirmation-window",
  login: {
    home: "/login/",
    forgotPassword: "forgot-password",
  },
  common: {
    notifications: "notifications",
    surveys: "surveys",
    surveyResponse: "surveys/:id/response",
    surveyResponseView: "surveys/:id/response/view",
    surveyCreate: "surveys/create",
    surveyEdit: "surveys/:id/edit",
    surveyResults: ":id/survey-results",
    developmentQuestionnaires: "development-questionnaires",
    developmentQuestionnairesResults: "development-questionnaires/:id/results",
    externalEvaluationForms: "external-evaluation-forms",
    externalEvaluationFormsResults: "external-evaluation-forms/:id/results",
    externalEvaluationFormView: "external-evaluation-forms/:id/view",
    programs: "programs",
    programView: "programs/:id",
    integrations: "integrations",
  },
  evaluator: {
    programView: "programs/:id",
    organizerInstitutions: "organizer-institutions",
    organizerInstitution: "organizer-institutions/:id",
  },
  specialist: {
    home: "/",
    institutions: "institutions",
    mentors: "mentors",
    mentorRequest: "mentors/request",
    mentorDecision: "mentors/decision/:id",
    mentees: "mentees",
    menteeRequest: "mentees/request",
    menteeDecision: "mentees/decision/:id",
    myCompetencies: "my-competencies",
    myDiplomas: "my-diplomas",
    obtainedDevelopments: "obtained-developments",
    obtainedDevelopmentCreate: "obtained-development-create",
    obtainedCompetencyCreate: "obtained-competency-create",
    certificateBasedOnProgramView: "certificate-based-on-program/:id",
    certificateOtherView: "certificate-other/:id",
    certificateWithoutDocumentView: "certificate-without-document/:id",
    certificateOtherEdit: "certificate-other/:id/edit",
    certificateWithoutDocumentEdit: "certificate-without-document/:id/edit",
    competency: "competency/:id/:type",
    outsidePlatformAcquiredCompetencyEdit: "competency/:id/outside-platform-acquired/edit",
    tieredCompetencies: "tiered-competencies",
    menteeView: "mentee-view/:id/:menteeName/:menteeLastName",
    viewMenteeExternalEvaluationFormsResults:
      "mentee-view/:id/:menteeName/:menteeLastName/external-evaluation-forms/:formId/results",
    developmentPlanQuestionnaires: "development-plan-questionnaires",
    developmentPlanQuestionnaireView: "development-plan-questionnaire/:questionnaireId/:developmentPlanId",
    developmentPlanView: "development-plan/:id/:user",
    profile: "profile",
    eventEvaluation: "reviews/event/:eventId",
  },
  healthCareEmployee: {},
  healthcareAdmin: {
    healthcareInstitutions: "health-care-institutions",
    specialists: "health-care-institutions/:id/specialists",
    specialistView: "health-care-institutions/:id/specialists/:sid/specialist",
    healthcareEmployees: "health-care-employees",
    healthcareEmployeesCreate: "health-care-employees/create",
    healthcareEmployeesEdit: "health-care-employees/:id/edit",
    professionalActivity: "professional-activity",
    notifications: "notifications",
  },
  controllingAuthority: {
    home: "/",
    healthcareInstitutions: "healthcare-institutions",
    healthcareEmployees: "healthcare-employees",
    specialists: "healthcare-institutions/:id/specialists",
    specialistView: "healthcare-institutions/:id/specialists/:sid/specialist",
    specialistSearch: "specialist-search",
    professionalActivity: "professional-activity",
    organizers: "organizers",
  },
  systemAdmin: {
    logs: "logs",
    settings: "settings",
    settingsLogsParameters: "settings/logs-parameters",
    settingsLogsParametersCreate: "settings/logs-parameters/create",
    settingsLogsParametersEdit: "settings/logs-parameters/:id/edit",
    systemParameters: "system-parameters",
    systemParameterValue: "system-parameter/:id",
    users: "users",
    userCreate: "users/create",
    userEdit: "users/:id/edit",
    classifiers: "classifiers",
    classifier: "classifiers/:id",
    classifierValueCreate: "classifiers/:id/values/create",
    classifierValueEdit: "classifiers/:id/values/:valueId/edit",
    notificationsTemplates: "notifications-templates",
    notificationsTemplateEdit: "notifications-templates/:id/edit",
    institutions: "institutions/:type?",
    institutionEdit: "institutions/:type/:id/edit",
    institutionCreate: "institutions/:type/create",
    institutionsRegistration: "institutions/registration/:id/:type",
    institutionEmployees: "institutions/:type/:id/employees",
    institutionEmployeeCreate: "institutions/:type/:id/employees/create",
    institutionEmployeeEdit: "institutions/:type/:id/employees/:eid/edit",
    tieredCompetencies: "tiered-competencies",
    tieredCompetenciesCreate: "tiered-competencies/create",
    tieredCompetenciesEdit: "tiered-competencies/:id/edit",
    residencyStudyPrograms: "tiered-competencies/:id",
    staticContentList: "static-content",
    staticContentCreate: "static-content/create",
    staticContentEdit: "static-content/:id/edit",
    staticContentView: "static-content/:id",
    impersonateSpecialist: "impersonate/specialist/:personalCode",
  },
  organizerAdmin: {
    organizers: "organizers",
    organizerCreate: "organizers/create",
    organizerEdit: "organizers/:id/edit",
    programCreate: "programs/create",
    programEdit: "programs/:id/edit",
    programDraftEdit: "programs/:id/draft/edit",
    events: "events",
    eventCreate: "events/create",
    eventView: "events/:id/view",
    eventEdit: "events/:id/edit",
    myInstitution: "institutions/:type/:id",
    institutionEdit: "institutions/:type/:id/edit",
    certificateCreate: "certificate-create",
    integrationCreate: "integrations/create",
  },
  organizerEmployee: {
    programCreate: "programs/create",
    programEdit: "programs/:id/edit",
  },
  hygieneSpecialist: {
    home: "/",
    evaluators: "evaluators",
    evaluatorCreate: "evaluators/create",
    evaluatorEdit: "evaluators/:id/edit",
    programView: "programs/:id",
    organizerInstitutions: "organizer-institutions",
    organizerInstitution: "organizer-institutions/:id",
    organizerInstitutionEmployees: "organizer-institutions/:id/employees",
  },
  analyst: {
    home: "/",
    supersetSingleSignOn: "superset-authorize",
  },
  questionnaireDeveloper: {
    home: "/",
    questionnaires: "questionnaires",
    questionnaireCreate: "questionnaires/create",
    questionnaireEdit: "questionnaires/:id/edit",
    questionnaireView: "questionnaires/:id/view",
  },
  aboutProject: "/about-project",
};
