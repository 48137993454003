import TagBase from "components/tag-base";
import { Fragment, FunctionComponent } from "react";
import { LearningProgram } from "types/program";
import { EventStatus, Event } from "types/event";
import dayjs from "dayjs";
import MissingParticipantsTag from "pages/organizer-admin/events/components/missing-participants-tag";
import { useAuth } from "providers/auth";
import { UserRole } from "types/user";
import { useDictionary } from "hooks/use-dictionary";
import { GenericStatusTag } from "components/generic-status-tag";

interface LearningEventTagsProps {
  program: LearningProgram;
  event: Event;
}

const statusMapping: {
  [key: string]: "primary" | "error" | "neutral" | "warning";
} = {
  FINISHED: "primary",
  CANCELED: "error",
  CREATED: "warning",
  FINISHED_WITHOUT_SPECIALISTS: "primary",
};

const LearningEventTags: FunctionComponent<LearningEventTagsProps> = ({ program, event }) => {
  const { user } = useAuth();
  const dict = useDictionary();

  return (
    <Fragment>
      {event.status !== EventStatus.CREATED && user && (
        <div className="mr-2 mb-2">
          <GenericStatusTag type={statusMapping[event.status]} text={dict.common.eventStatus[event.status]} />
        </div>
      )}
      {(user?.role === UserRole.ROLE_ORGANIZER_ADMIN || user?.role === UserRole.ROLE_ORGANIZER_EMPLOYEE) && (
        <>
          {event.status === EventStatus.FINISHED && event.isClosed === false && !event.specialistUsers?.length && (
            <div className="mr-2 mb-2">
              <MissingParticipantsTag />
            </div>
          )}
        </>
      )}
      {program.practiceTypeSpecializationCompetencyClassifiers?.map((x) => (
        <TagBase key={x.id} className="mr-2 mb-2">
          {x.valueName}
        </TagBase>
      ))}
    </Fragment>
  );
};

export default LearningEventTags;
