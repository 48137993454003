import axios from "axios";
import { API_URL, TOKEN } from "constants/api";
import { versionManagement } from "utils/version-management";

const apiClient = axios.create({
  baseURL: `${API_URL}/api`,
  validateStatus: () => true,
});

apiClient.interceptors.request.use((config) => {
  const token = localStorage.getItem(TOKEN);
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

apiClient.interceptors.response.use((response) => {
  const version = response.headers?.['x-app-version'];
                 
  if (version) {
    versionManagement.checkAndHandleVersion(version);
  }

  return response;
});

export default apiClient;
