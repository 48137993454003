import { useEffect } from "react";
import { useDictionary } from "./use-dictionary";
import * as CookieConsent from "vanilla-cookieconsent";

export const useCookieConsent = () => {
  const dict = useDictionary();

  useEffect(() => {
    CookieConsent.run({
        categories: {
            necessary: {
                enabled: true,  // this category is enabled by default
                readOnly: true  // this category cannot be disabled
            },
        },
        language: {
            default: 'lt',
            translations: {
                lt: {
                    consentModal: {
                        title: dict.cookieConstent.title,
                        description: dict.cookieConstent.description,
                        acceptAllBtn: dict.cookieConstent.acceptAll,
                        acceptNecessaryBtn: dict.cookieConstent.acceptNecessary,
                        showPreferencesBtn: dict.cookieConstent.showPreferences
                    },
                    preferencesModal: {
                        title: dict.cookieConstent.title,
                        acceptAllBtn: dict.cookieConstent.acceptAll,
                        acceptNecessaryBtn: dict.cookieConstent.acceptNecessary,
                        savePreferencesBtn: dict.cookieConstent.savePreferences,
                        closeIconLabel: dict.cookieConstent.close,
                        sections: [
                            {
                                title: dict.cookieConstent.necessaryCookiesSection.title,
                                description: dict.cookieConstent.necessaryCookiesSection.description,
                                linkedCategory: 'necessary',
                                cookieTable: {
                                  headers: {
                                      name: dict.cookieConstent.cookiesTable.header.name,
                                      description: dict.cookieConstent.cookiesTable.header.description,
                                      expiration: dict.cookieConstent.cookiesTable.header.expiration
                                  },
                                  body: [
                                      {
                                          name: dict.cookieConstent.cookiesTable.body[0].title,
                                          description: dict.cookieConstent.cookiesTable.body[0].description,
                                          expiration: dict.cookieConstent.cookiesTable.body[0].expiration
                                      },
                                      {
                                          name: dict.cookieConstent.cookiesTable.body[1].title,
                                          description: dict.cookieConstent.cookiesTable.body[1].description,
                                          expiration: dict.cookieConstent.cookiesTable.body[1].expiration
                                      },
                                      {
                                          name: dict.cookieConstent.cookiesTable.body[2].title,
                                          description: dict.cookieConstent.cookiesTable.body[2].description,
                                          expiration: dict.cookieConstent.cookiesTable.body[2].expiration
                                      },
                                      {
                                          name: dict.cookieConstent.cookiesTable.body[3].title,
                                          description: dict.cookieConstent.cookiesTable.body[3].description,
                                          expiration: dict.cookieConstent.cookiesTable.body[3].expiration
                                      },
                                      {
                                          name: dict.cookieConstent.cookiesTable.body[4].title,
                                          description: dict.cookieConstent.cookiesTable.body[4].description,
                                          expiration: dict.cookieConstent.cookiesTable.body[4].expiration
                                      },
                                      {
                                          name: dict.cookieConstent.cookiesTable.body[5].title,
                                          description: dict.cookieConstent.cookiesTable.body[5].description,
                                          expiration: dict.cookieConstent.cookiesTable.body[5].expiration
                                      }
                                  ]
                                }
                            }
                        ]
                    }
                }
            }
        }
    });
  }, []);
}