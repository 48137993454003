import { useEffect } from "react"
import { useNavigate } from "./use-navigate";
import { useAuth } from "providers/auth";
import { ROUTES } from "constants/routes";
import { useRedirectAfterLoginUrlHandler } from "./use-redirect-after-login-url-handler";

export const useRedirectAfterLogin = () => {
  const { redirectAfterLoginToUrlRef, reset, setRedirectUrl } = useRedirectAfterLoginUrlHandler();
  const navigate = useNavigate();
  const { isReady, user } = useAuth();

  useEffect(() => {
    if (redirectAfterLoginToUrlRef.current && isReady && user) {
      const copyOfRedirectAfterLoginToUrl = redirectAfterLoginToUrlRef.current.repeat(1);

      reset();
      navigate(copyOfRedirectAfterLoginToUrl);
    }
  }, [isReady, user, navigate, redirectAfterLoginToUrlRef, reset]);

  const setRedirectUrlAndRedirectToLogin = () => {
    if (window.location.pathname === redirectAfterLoginToUrlRef.current) {
      return;
    }
    
    setRedirectUrl(window.location.pathname);
    window.location.href = ROUTES.login.home;
  }

  return {
    setRedirectUrlAndRedirectToLogin,
    reset
  }
}