import { useRedirectAfterLogin } from "hooks/use-redirect-after-login";
import { useRedirectAfterLoginUrlHandler } from "hooks/use-redirect-after-login-url-handler";
import { useAuth } from "providers/auth"
import { useEffect } from "react";
import { Outlet } from "react-router-dom"

export const AuthenticatedLayout = () => {
  const { isReady, user } = useAuth();
  const { setRedirectUrlAndRedirectToLogin } = useRedirectAfterLogin();
  const { redirectAfterLoginToUrlRef } = useRedirectAfterLoginUrlHandler();

  useEffect(() => {
    if (isReady && !user && !redirectAfterLoginToUrlRef.current) {
      setRedirectUrlAndRedirectToLogin();
    }
  }, [isReady]);

  if (!isReady || (isReady && !user && !redirectAfterLoginToUrlRef.current)) {
    return null;
  }

  return <Outlet />;
}