import { FunctionComponent, useEffect, useMemo, useState } from "react";
import DescriptionIcon from "@mui/icons-material/Description";
import { FileRecord } from "types/file-record";
import { useDownloadFile } from "hooks/api/use-download-file";

interface FileViewButtonProps {
  file: FileRecord | File;
}

const FileViewButton: FunctionComponent<FileViewButtonProps> = ({ file }) => {
  const [selectedDocumentId, setSelectedDocumentId] = useState<string|number>("");
  const { data: downloadedFile } = useDownloadFile(selectedDocumentId);

  useEffect(() => {
    if (downloadedFile) {
      const url = window.URL.createObjectURL(new Blob([downloadedFile]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      if (link.parentNode) {
        link.parentNode.removeChild(link);
      }
      window.URL.revokeObjectURL(url);
      setSelectedDocumentId("");
    }
  }, [downloadedFile, selectedDocumentId]);

  const fileName = useMemo(() => {
    if (file instanceof File) {
      return file.name;
    }

    return file.fileName;
  }, [file]);

  const fileId = useMemo(() => {
    if (file instanceof File) {
      return file.name;
    }

    return file.id;
  }, [file]);

  return (
    <div key={fileName} className="flex items-center space-x-2">
      <DescriptionIcon />
      <a
        target="_blank"
        onClick={() => {
          setSelectedDocumentId(fileId);
        }}
        rel="noreferrer"
        className="text-theme-primary hover:underline cursor-pointer"
      >
        {fileName}
      </a>
    </div>
  );
};

export default FileViewButton;
