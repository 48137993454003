import { useDictionary } from "hooks/use-dictionary";
import { useGetExternalEvaluationForm } from "hooks/api/common/external-evaluation-form/use-get-external-evaluation-form";
import { generatePath, Navigate } from "react-router-dom";
import { useRequiredParams } from "hooks/use-required-params";
import { ROUTES } from "constants/routes";
import Layout from "components/layout";
import HeroImage from "assets/home/hero.png";
import Container from "components/container";
import ExternalEvaluationResponseForm from "./form/external-evaluation-response-form";
import { FormQuestionnaireResponse } from "./form/external-evaluation-response-form";
import { useUpdateEvaluationQuestionnaireUserRecord } from "hooks/api/specialist/use-update-evaluation-questionnaire-user-record";
import { useGetExternalEvaluationFormStatus } from "hooks/api/common/external-evaluation-form/use-get-external-evaluation-form-status";
import { useCallback, useEffect } from "react";
import { isSuccessStatus } from "utils/is-success-status";
import { queryClient } from "query-client";
import { QUERY_KEYS } from "constants/query-keys";
import { useSnackbar } from "notistack";
import { getFlatErrorMessage } from "utils/get-readable-errors";
import { FlatApiError } from "types/api";
import { useNavigate } from "react-router-dom";
import { EvaluationQuestionnaireUserRecordStatusEnum } from "types/questionnaire";

const ExternalEvaluationFormAnswerPage = () => {
  const dict = useDictionary();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { publicId } = useRequiredParams<{ publicId: string }>();

  const { data: externalEvaluationForm, isLoading: isFormLoading } = useGetExternalEvaluationForm(publicId);
  const { data: externalEvaluationFormStatus } = useGetExternalEvaluationFormStatus(publicId);

  useEffect(() => {
    if (externalEvaluationFormStatus?.status === EvaluationQuestionnaireUserRecordStatusEnum.SUBMITTED) {
      navigate(ROUTES.home + generatePath(ROUTES.externalEvaluationFormAnswer.answered, { publicId }), {
        state: { isOpenedAfterSubmit: false },
      });
    }
  }, [externalEvaluationFormStatus?.status, navigate, publicId]);

  const { mutate: update, isPending } = useUpdateEvaluationQuestionnaireUserRecord();

  const onSubmit = useCallback(
    async (formData: FormQuestionnaireResponse) => {
      if (!publicId) return;

      const dataToSend = {
        questionnaireQuestionUserRecords: formData.questionnaireQuestionUserRecords.map(
          ({ type, required, selectedOptions = [], ...rest }) => ({
            ...rest,
            selectedOptions: selectedOptions
              .filter((option) => option && Object.keys(option).length > 0)
              .map(({ textRequired, ...optionsRest }) => optionsRest),
          })
        ),
      };

      update(
        {
          publicId: publicId,
          submissionData: dataToSend,
        },
        {
          onSettled: (response) => {
            if (response && isSuccessStatus(response.status)) {
              queryClient.invalidateQueries({
                queryKey: [QUERY_KEYS.questionnaires],
              });
              navigate(ROUTES.home + generatePath(ROUTES.externalEvaluationFormAnswer.answered, { publicId }), {
                state: { isOpenedAfterSubmit: true },
              });
            } else {
              enqueueSnackbar({
                variant: "baseError",
                message:
                  getFlatErrorMessage(response?.data as FlatApiError) ??
                  dict.messages.externalEvaluationFormAnswerSaveError,
                errors: [],
              });
            }
          },
        }
      );
    },
    [dict, enqueueSnackbar, publicId, update, navigate]
  );

  if (isFormLoading) {
    return null;
  }

  if (!externalEvaluationForm) {
    return <Navigate to={ROUTES.user} />;
  }

  return (
    <Layout>
      <div className="bg-center bg-cover" style={{ backgroundImage: `url(${HeroImage})` }}>
        <Container className="bg-theme-text/55">
          <div className="justify-center flex py-20">
            <div className="bg-white rounded py-16 px-6 w-full">
              <div className="space-y-6">
                <div className="text-3xl text-center leading-tight font-semibold">
                  {dict.questionnaires.externalEvaluationForm}
                </div>
                <div className="h-0.5 bg-theme-primary" />

                <div className="text-2xl font-semibold">{externalEvaluationForm.title}</div>
                <ExternalEvaluationResponseForm
                  questionnaireToRespond={externalEvaluationForm}
                  isPending={isPending}
                  onSubmit={onSubmit}
                />
              </div>
            </div>
          </div>
        </Container>
      </div>
    </Layout>
  );
};

export default ExternalEvaluationFormAnswerPage;
