import { createContext, PropsWithChildren, useEffect } from "react";
import { useAuth } from "./auth";
import apiClient from "api/api-client";
import { TOKEN } from "constants/api";

const AxiosInterceptorsContext = createContext(null);

export const AxiosInterceptorsProvider = ({ children }: PropsWithChildren) => {
  const { logout } = useAuth();

  useEffect(() => {
    apiClient.interceptors.response.use(
      (response) => {
        if (response && response.status === 401) {
          if (localStorage.getItem(TOKEN)) {
            logout();
          }
        }
    
        return response;
      }
    );
  }, []);

  return (
    <AxiosInterceptorsContext.Provider value={null}>
      {children}
    </AxiosInterceptorsContext.Provider>
  )
}