import { useRef } from "react"

const REDIRECT_AFTER_LOGIN_URL_LOCAL_STORAGE_KEY = 'redirectAfterLoginToUrl';

export const useRedirectAfterLoginUrlHandler = () => {
  const redirectAfterLoginToUrlRef = useRef<string | null>(localStorage.getItem(REDIRECT_AFTER_LOGIN_URL_LOCAL_STORAGE_KEY));

  const setRedirectUrl = (path: string) => {
    redirectAfterLoginToUrlRef.current = path;
    localStorage.setItem(REDIRECT_AFTER_LOGIN_URL_LOCAL_STORAGE_KEY, path);
  }

  const reset = () => {
    localStorage.removeItem(REDIRECT_AFTER_LOGIN_URL_LOCAL_STORAGE_KEY);
    redirectAfterLoginToUrlRef.current = null;
  }

  return {
    setRedirectUrl,
    reset,
    redirectAfterLoginToUrlRef
  }
}