import apiClient from "api/api-client";
import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "constants/query-keys";

export const useDownloadFile = (id: string|number) => {
  return useQuery({
    queryKey: [QUERY_KEYS.downloadFile, id],
    queryFn: async () => {
      if (id === "") return;
      const response = await apiClient.get(`/files/${id}`, {
        responseType: "blob",
      });
      return response.data;
    },
  });
};
