import classNames from "classnames";
import { FunctionComponent, useEffect } from "react";
import MuiTablePagination from "@mui/material/TablePagination";
import { IconButton, Pagination } from "@mui/material";
import { useDictionary } from "hooks/use-dictionary";
import { useBreakpoint } from "hooks/use-breakpoint";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { interpolate } from "utils/interpolate";
import { useLocation, useNavigate } from "react-router-dom";

interface TablePaginationProps {
  pages: number;
  page: number;
  limit: number;
  count: number;
  onLimit: (val: number) => void;
  onPage: (val: number) => void;
  className?: string;
  rowsPerPageOptions?: number[];
}

const TablePaginationActions: FunctionComponent<any> = ({ count, page, rowsPerPage, onPageChange }) => {
  const dict = useDictionary();
  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  return (
    <div style={{ flexShrink: 0, marginLeft: "2.5rem" }}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label={dict.common.ariaLabels.previousPage}
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={dict.common.ariaLabels.nextPage}
      >
        <KeyboardArrowRight />
      </IconButton>
    </div>
  );
};

const TablePagination: FunctionComponent<TablePaginationProps> = ({
  className,
  pages,
  page,
  limit,
  count,
  onLimit,
  onPage,
  rowsPerPageOptions = [10, 25, 50, 100],
}) => {
  const { maxTablet } = useBreakpoint();
  const dict = useDictionary();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (page > pages) {
      onPage(1);
    }
  }, [pages, page, onPage]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const urlPage = params.get("page");
    if (urlPage && Number(urlPage) !== page && Number(urlPage) <= pages) {
      onPage(Number(urlPage));
    }
  }, [location.search, page, onPage]);

  const handlePageChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
    onPage(newPage);
    const params = new URLSearchParams(location.search);
    params.set("page", newPage.toString());
    navigate({ search: params.toString() });
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newLimit = +event.target.value;
    const currentOffset = (page - 1) * limit;
    const newPage = Math.floor(currentOffset / newLimit) + 1;
    onLimit(newLimit);
    onPage(newPage);
  };

  const getItemAriaLabel = (type: string, page: number, selected: boolean) => {
    return interpolate(dict.common.ariaLabels.pageNumber, { value: `${page}` });
  };

  return (
    <div className={classNames("flex justify-between flex-wrap gap-4 items-center", className)}>
      <div className={classNames("flex gap-1.5 flex-wrap", { "w-full": maxTablet })}>
        <Pagination
          siblingCount={maxTablet ? 0 : 1}
          count={pages}
          page={page}
          onChange={handlePageChange}
          hideNextButton={!maxTablet}
          hidePrevButton={!maxTablet}
          getItemAriaLabel={getItemAriaLabel}
          sx={
            maxTablet
              ? {
                  width: "100%",
                  marginTop: "16px",
                  "& .MuiPagination-ul": {
                    display: "flex",
                    justifyContent: "start",
                  },
                  "& .MuiPaginationItem-root.Mui-selected": {
                    color: "#ffffff",
                    border: "2px solid #20694B",
                    borderRadius: "50%",
                    backgroundColor: "#20694B !important",
                  },
                }
              : {
                  marginTop: "4px",
                  "& .MuiPaginationItem-root.Mui-selected": {
                    color: "#ffffff",
                    border: "2px solid #20694B",
                    borderRadius: "50%",
                    backgroundColor: "#20694B !important",
                  },
                }
          }
        />
      </div>
      <table className={classNames({ "w-full": maxTablet })}>
        <tbody>
          <tr>
            <MuiTablePagination
              onPageChange={(e, p) => handlePageChange(e!, p + 1)}
              onRowsPerPageChange={handleRowsPerPageChange}
              page={page - 1}
              rowsPerPage={limit}
              count={count}
              rowsPerPageOptions={maxTablet ? undefined : rowsPerPageOptions}
              labelRowsPerPage={maxTablet ? "" : dict.common.pageRowCount}
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} iš ${count}`}
              showFirstButton={false}
              ActionsComponent={TablePaginationActions}
              slotProps={{
                actions: maxTablet
                  ? {
                      nextButton: { style: { display: "none" } },
                      previousButton: { style: { display: "none" } },
                    }
                  : {},
                select: {
                  inputProps: {
                    "aria-hidden": false,
                  },
                },
              }}
              sx={{
                "& .MuiTablePagination-spacer, & .MuiTablePagination-selectLabel, & .MuiTablePagination-actions": {
                  display: maxTablet ? "none" : "block",
                },
                "& .MuiToolbar-root": {
                  paddingLeft: 0,
                  display: "flex",
                  justifyContent: "space-between",
                },
                "& .MuiTablePagination-select": {
                  color: "#ffffff",
                  backgroundColor: "#20694B",
                  borderRadius: "8px",
                },
                "& .MuiTablePagination-select:focus": {
                  backgroundColor: "#20694B !important",
                  borderRadius: "8px",
                },
                "& .MuiSelect-icon": {
                  color: "#ffffff",
                },
              }}
            />
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TablePagination;
