import Container from "components/container";
import { ReactComponent as FacebookIcon } from "assets/social-icons/facebook.svg";
import IconButton from "components/icon-button";
import { useDictionary } from "hooks/use-dictionary";

const Footer = () => {
  const dict = useDictionary();
  return (
    <Container className="border-b-theme-primary" style={{ borderBottomWidth: "16px" }}>
      <div className="border-b py-6 flex items-center justify-between flex-wrap gap-6">
        <div className="flex">
          <div className="text-theme-primary pr-3 mr-3 border-r">{dict.footer.VASPVT}</div>
          <div>{dict.footer.competencyPlatform}</div>
        </div>
        <div className="flex gap-4">
          <IconButton
            onClick={() => window.open("https://www.facebook.com/akreditavimotarnyba/?locale=lt_LT", "_blank")}
          >
            <FacebookIcon />
          </IconButton>
        </div>
      </div>
      <div className="pt-6 pb-14 grid grid-cols-1 sm:grid-cols-2 gap-y-4">
        <div>
          <div className="font-bold">{dict.footer.code}</div>
          <div>{dict.footer.codeValue}</div>
        </div>
        <div>
          <div className="font-bold">{dict.footer.address}</div>
          <div>{dict.footer.addressValue}</div>
        </div>
        <div>
          <div className="font-bold">{dict.footer.email}</div>
          <div>
            <a className="text-theme-primary hover:underline cursor-pointer" href={`mailto:${dict.footer.emailValue}`}>
              {dict.footer.emailValue}
            </a>
          </div>
        </div>
        <div>
          <div className="font-bold">{dict.footer.phoneNumber}</div>
          <div>
            <a
              className="text-theme-primary hover:underline cursor-pointer"
              href={`tel:${dict.footer.phoneNumberValue}`}
            >
              {dict.footer.phoneNumberValue}
            </a>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Footer;
