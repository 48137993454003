import { ThemeProvider } from "@emotion/react";
import theme from "theme";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AuthProvider from "providers/auth";
import Pages from "pages";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "query-client";
import LoadingProvider from "providers/loading";
import SnackbarProvider from "providers/snackbar";
import { ViewingOtherSpecialistInformationContextProvider } from "providers/viewing-other-specialist-information";
import { AxiosInterceptorsProvider } from "providers/axios-interceptors";
import "vanilla-cookieconsent/dist/cookieconsent.css";
import { useCookieConsent } from "hooks/use-cookie-consent";

const App = () => {
  useCookieConsent();

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="lt">
          <SnackbarProvider>
            <LoadingProvider>
              <AuthProvider>
                <AxiosInterceptorsProvider>
                  <ViewingOtherSpecialistInformationContextProvider>
                    <Pages />
                  </ViewingOtherSpecialistInformationContextProvider>
                </AxiosInterceptorsProvider>
              </AuthProvider>
            </LoadingProvider>
          </SnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
