import { useLocation } from "react-router-dom";

export const usePageAndTab = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const page = searchParams.get("page") || 1;
  const tab = searchParams.get("tab") || "";

  const constructUrlParams = (overridePage?: number | string, overrideTab?: number) => {
    const finalPage = overridePage !== undefined ? overridePage : page;
    const finalTab = overrideTab !== undefined ? overrideTab : tab;
    return `?page=${finalPage}${finalTab ? `&tab=${finalTab}` : ""}`;
  };

  return { page, tab, constructUrlParams };
};
