import classNames from "classnames";
import { ComponentProps, FunctionComponent } from "react";

interface IconButtonProps extends ComponentProps<"div"> {
  size?: string;
  variant?: 'ghost'
}

const IconButton: FunctionComponent<IconButtonProps> = ({ className, children, size, variant, ...props }) => {
  return (
    <div
      {...props}
      className={classNames(
        "rounded-full border hover:bg-theme-gray100 cursor-pointer flex items-center justify-center hover:text-theme-primary",
        size,
        className,
        {
          "w-10 h-10": size == null,
          'border-0': variant === 'ghost',
          'hover:bg-transparent': variant === 'ghost',
        }
      )}
    >
      {children}
    </div>
  );
};

export default IconButton;
