import { createContext, PropsWithChildren, useContext, useState } from 'react';

type ViewingOtherSpecialistInformationContextType = {
  isViewingOtherSpecialistInformation: boolean;
  toggleViewingOtherSpecialistInformation: () => void;
  reset: () => void;
}

const ViewingOtherSpecialistInformationContext = createContext<ViewingOtherSpecialistInformationContextType>({} as any);

export const useViewingOtherSpecialistInformation = () => useContext(ViewingOtherSpecialistInformationContext);

export const ViewingOtherSpecialistInformationContextProvider = ({ children }: PropsWithChildren) => {
  const [isViewingOtherSpecialistInformation, setIsViewingOtherSpecialistInformation] = useState(false);
  const reset = () => setIsViewingOtherSpecialistInformation(false);

  return (
    <ViewingOtherSpecialistInformationContext.Provider value={{
      isViewingOtherSpecialistInformation,
      toggleViewingOtherSpecialistInformation: () => setIsViewingOtherSpecialistInformation(!isViewingOtherSpecialistInformation),
      reset
    }}>
      {children}
    </ViewingOtherSpecialistInformationContext.Provider>
  );
}