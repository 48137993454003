import useFormattedDate from "hooks/use-formatted-date";
import StarsRating from "./stars-rating";
import { Event } from "types/event";
import { interpolate } from "utils/interpolate";
import { useDictionary } from "hooks/use-dictionary";
import { ComponentProps } from "react";
import classNames from "classnames";

interface EventCardProps extends ComponentProps<"div"> {
  event: Event;
}

const EventCard = ({ event, className, ...props }: EventCardProps) => {
  const eventStartDate = useFormattedDate(event.startDate);
  const eventEndDate = useFormattedDate(event.endDate);
  const dict = useDictionary();
  return (
    <div className={classNames("space-border bg-white p-4 rounded space-y-2.5-3 break-words", className)} {...props}>
      <div className="flex"></div>
      <div className="text-lg line-clamp-4">{event.name}</div>
      <div className="text-sm text-theme-gray300">{event.organizerInstitution.name}</div>
      {event.website && <div className="text-sm text-theme-gray300 break-all">{event.website}</div>}
      <div className="text-lg line-clamp-4">
        {eventStartDate} - {eventEndDate}
      </div>
      <StarsRating reviews={event.program.reviewRating} />
      <div className="flex justify-between text-lg">
        <div>
          {interpolate(dict.organizerAdmin.events.priceValue, {
            value: `${event.price}`,
          })}
        </div>
        {event.program.duration && (
          <div>{interpolate(dict.organizerAdmin.events.durationValue, { value: `${event.program.duration}` })}</div>
        )}
      </div>
    </div>
  );
};

export default EventCard;
