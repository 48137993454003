import React, { ReactNode, useEffect, useRef } from "react";

type SubmitOnEnterKeyPressProps = {
  children: ReactNode;
}

export const SubmitOnEnterKeyPress = ({ children }: SubmitOnEnterKeyPressProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (
        wrapperRef.current && 
        event.key === "Enter" && 
        !event.shiftKey && 
        wrapperRef.current.contains(document.activeElement)
      ) {
        if (document.activeElement instanceof HTMLInputElement ||
            document.activeElement instanceof HTMLTextAreaElement ||
            document.activeElement instanceof HTMLSelectElement) {
          event.preventDefault();
          event.stopPropagation();

          const submitButton = wrapperRef.current.querySelector('button[type="submit"]');
          if (submitButton instanceof HTMLButtonElement) {
            submitButton.click();
          }
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return <div ref={wrapperRef}>{children}</div>;
};