import { OrganizerInstitution } from "./institution/organizer";
import {
  DevelopmentProgram,
  FormDevelopmentCompetencyGroupClassifier,
  FormMandatoryTopicClassifier,
  LearningProgram,
  ProgramType,
} from "./program";
import { CertificateOther } from "./certificate";

export interface Event {
  id: number;
  price: string;
  name: string;
  startDate: string;
  endDate: string;
  description: string;
  registrationDate: string;
  status: EventStatus;
  international: boolean;
  eventHours: number;
  eventMandatoryHours: number;
  identification: string;
  isClosed: boolean;
  place: string | null;
  program: DevelopmentProgram | LearningProgram;
  organizerInstitution: OrganizerInstitution;
  specialistUsers: EventParticipant[];
  certificates: CertificateOther[];
  website: string;
  updatedAt: string;
  programFormValueName: string;
}

export interface FormEvent {
  program: number;
  programType: ProgramType;
  startDate: string;
  endDate: string;
  description: string;
  place?: string;
  website?: string | null;
  duration?: number | Number;
  price: number;
  targetedProgramClassifiers?: string[];
  targetedAudienceClassifiers?: string[];
  mandatoryTopicClassifiers?: FormMandatoryTopicClassifier[];
  eventForm?: string; // only by development program
  international?: boolean; // only by development program
  developmentCompetencyGroupClassifiers?: FormDevelopmentCompetencyGroupClassifier[]; //only by development program
  acquiredCompetencyClassifiers?: string[]; // only by learning program
  programFormClassifierName?: string | null;
}

export interface BodyEvent {
  international?: boolean;
  price: string;
  place?: string;
  endDate: string;
  startDate: string;
  program: number;
  website?: string;
  description: string;
}

export enum EventStatus {
  CREATED = "CREATED",
  CANCELED = "CANCELED",
  FINISHED = "FINISHED",
  FINISHED_WITHOUT_SPECIALISTS = "FINISHED_WITHOUT_SPECIALISTS",
}

export type EventsFilters = {
  startDate: string | null;
  endDate: string | null;
  name: string | null;
  eventHours: number | null;
  programType: ProgramType | null;
};

export type PublicEventsFilters = {
  name: string | null;
  startDate: string | null;
  endDate: string | null;
  organizerInstitution: string | null;
  developmentCompetencyGroupClassifier: string | null;
  programType: ProgramType | null;
  eventHours: number | null;
  review: number | null;
  price: number | null;
};

export interface EventParticipant {
  id: number;
  firstName: string;
  lastName: string;
  code: number;
}

export interface FormEventParticipant extends Omit<EventParticipant, "id"> {}

export interface EventVersion {
  id: number;
  data: Omit<Event, "program"> & Partial<Pick<Event, "program">>;
  createdAt: string;
  program: DevelopmentProgram | LearningProgram;
}
