import { useMemo } from "react";
import dayjs from "dayjs";

const useFormattedDate = (date?: string | null, options: { defaultToNothing: boolean } = { defaultToNothing: false }) => {
  return useMemo(() => {
    if (!date && options.defaultToNothing) {
      return undefined;
    }

    if (!date && !options.defaultToNothing) {
      return "-";
    }
    return dayjs(date).format("YYYY-MM-DD");
  }, [date, options.defaultToNothing]);
};

export default useFormattedDate;
