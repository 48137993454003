const VERSION_KEY = 'app_version';

export const versionManagement = {
  getCurrentVersion: () => {
    return localStorage.getItem(VERSION_KEY);
  },

  setVersion: (version: string) => {
    localStorage.setItem(VERSION_KEY, version);
  },

  checkAndHandleVersion: (responseVersion: string) => {
    const currentVersion = versionManagement.getCurrentVersion();
    
    if (currentVersion && currentVersion !== responseVersion) {
      versionManagement.setVersion(responseVersion);
      window.location.reload();
    } else if (!currentVersion) {
      versionManagement.setVersion(responseVersion);
    }
    
  }
};
