import DescriptionIcon from "@mui/icons-material/Description";
import { useDictionary } from "hooks/use-dictionary";
import { useAuth } from "providers/auth";
import { UserRole } from "types/user";

interface EmptyStateProps {
  title?: string;
  text?: string;
  hideDescription?: boolean;
}

const EmptyState = ({ title, text, hideDescription = false }: EmptyStateProps) => {
  const dict = useDictionary();
  const { user } = useAuth();
  return (
    <div className="flex flex-col items-center justify-center w-full text-center bg-white py-2">
      <DescriptionIcon style={{ width: 40, height: 40 }} className="mb-3 text-gray-600" aria-hidden="false" />
      <div className="font-semibold mb-1 text-base">{title ? title : dict.common.noTitle}</div>
      {!hideDescription && (<div className="text-sm max-w-[300px] mx-auto">
        {text ? text : user?.role === UserRole.ROLE_SYSTEM_ADMIN ? dict.emptyState.systemAdmin : dict.common.noData}
      </div>)}
    </div>
  );
};
export default EmptyState;
