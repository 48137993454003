import { FlatApiError, NestedApiError } from "types/api";

export interface ReadableError {
  label: string;
  errors: string[];
}

export const getReadableErrors = (error: FlatApiError): ReadableError[] => {
  let errors: ReadableError[] = [];
  if (error.errors && error.errors.length > 0) {
    error.errors.forEach((err) => {
      const label = err.field;
      errors.push({ label: label, errors: [err.message] });
    });
  }

  return errors;
};

export const getFlatErrorMessage = (error: FlatApiError): string => {
  let message = "";
  if (error.errors && error.errors.length > 0) {
    message = error.errors[0].message;
  }
  return message;
};

export const getNestedErrorMessage = (error: NestedApiError): string => {
  let message = "";

  const extractMessages = (errors: any): string[] => {
    let messages: string[] = [];
    if (errors.errors && errors.errors.length > 0) {
      messages = messages.concat(errors.errors);
    }
    if (errors.children) {
      for (const key in errors.children) {
        messages = messages.concat(extractMessages(errors.children[key]));
      }
    }
    return messages;
  };

  const errorMessages = extractMessages(error.errors);
  if (errorMessages.length > 0) {
    message = errorMessages[0];
  }

  return message;
};

export const getFirstErrorMessage = (error: FlatApiError): string => {
  let message = "";

  if (error.errors && error.errors.length > 0) {
    message = error.errors[0].message;
  } else if (error.message) {
    message = error.message;
  }

  if (message.includes("\n")) {
    message = message.split("\n")[0];
  }

  return message;
};

export const getAllErrorMessages = (error: FlatApiError): string => {
  let messages: string[] = [];

  if (error.errors && error.errors.length > 0) {
    messages = error.errors.map((err) => err.message);
  } else if (error.message) {
    messages.push(error.message);
  }

  return messages.join("\n");
};
