import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers";
import { useDictionary } from "hooks/use-dictionary";
import { useState } from "react";
import { PublicEventsFilters } from "types/event";
import { ProgramType } from "types/program";
import { MenuItem } from "@mui/material";
import dayjs from "dayjs";
import { usePublicOrganizerInstitutions } from "hooks/api/use-public-organizer-institutions";

interface FiltersBarProps {
  onSubmit: (val: PublicEventsFilters) => void;
  onClear: () => void;
}

const defaultFilters: PublicEventsFilters = {
  name: null,
  startDate: null,
  endDate: null,
  organizerInstitution: null,
  developmentCompetencyGroupClassifier: null,
  programType: null,
  eventHours: null,
  review: null,
  price: null,
};

const FiltersBar = ({ onSubmit, onClear }: FiltersBarProps) => {
  const { items: institutions } = usePublicOrganizerInstitutions();

  const [filters, setFilters] = useState<PublicEventsFilters>(defaultFilters);
  const programType = [ProgramType.DEVELOPMENT_TYPE, ProgramType.LEARNING_TYPE];
  const dict = useDictionary();

  return (
    <div className="rounded bg-white border p-5 grid sm:grid-cols-2 gap-5">
      <TextField
        label={dict.organizerAdmin.events.name}
        value={filters.name ?? ""}
        onChange={(e) => {
          setFilters((prev) => ({ ...prev, name: e.target.value || null }));
        }}
      />
      <DatePicker
        label={dict.organizerAdmin.events.eventStartDate}
        value={filters.startDate}
        format="YYYY-MM-DD"
        onChange={(val) => {
          setFilters((prev) => ({ ...prev, startDate: val ? dayjs(val).format() : null }));
        }}
      />
      <DatePicker
        label={dict.organizerAdmin.events.eventEndDate}
        value={filters.endDate}
        format="YYYY-MM-DD"
        onChange={(val) => {
          setFilters((prev) => ({ ...prev, endDate: val ? dayjs(val).format() : null }));
        }}
      />
      <TextField
        label={dict.organizerAdmin.events.organizer}
        value={filters.organizerInstitution ?? ""}
        select
        onChange={(e) => {
          setFilters((prev) => ({ ...prev, organizerInstitution: e.target.value || null }));
        }}
      >
        {institutions.map((institution, i) => (
          <MenuItem key={i} value={institution.id}>
            {institution.name}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        label={dict.organizerAdmin.events.type}
        value={filters.programType ?? ""}
        select
        onChange={(e) => {
          setFilters((prev) => ({ ...prev, programType: (e.target.value as ProgramType) || null }));
        }}
      >
        {programType.map((type, i) => (
          <MenuItem key={i} value={type}>
            {dict.common.programType[type]}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        label={dict.organizerAdmin.events.duration}
        type="number"
        value={filters.eventHours ?? ""}
        onWheel={(e) => (e.target as HTMLElement).blur()}
        onKeyDown={(e) => {
          if (["e", "-", "+", "."].includes(e.key.toLowerCase())) {
            e.preventDefault();
          }
        }}
        onChange={(e) => {
          let val = parseFloat(e.target.value);
          if (val < 1) val = 1;
          setFilters((prev) => ({ ...prev, eventHours: val != null ? +val : null }));
        }}
        inputProps={{ min: 1 }}
      />
      <TextField
        label={dict.organizerAdmin.events.minimalReview}
        type="number"
        value={filters.review ?? ""}
        onWheel={(e) => (e.target as HTMLElement).blur()}
        onKeyDown={(e) => {
          if (["e", "-", "+"].includes(e.key.toLowerCase())) {
            e.preventDefault();
          }
        }}
        onChange={(e) => {
          let val = parseFloat(e.target.value);
          if (val < 0.1) val = 0.1;
          if (val > 5) val = 5;
          setFilters((prev) => ({ ...prev, review: val }));
        }}
        inputProps={{ min: 0.1, max: 5, step: 0.1 }}
      />
      <TextField
        label={dict.organizerAdmin.events.maxPrice}
        type="number"
        value={filters.price ?? ""}
        onWheel={(e) => (e.target as HTMLElement).blur()}
        onKeyDown={(e) => {
          if (["e", "-", "+", "."].includes(e.key.toLowerCase())) {
            e.preventDefault();
          }
        }}
        onChange={(e) => {
          let val = parseFloat(e.target.value);
          if (val < 1) val = 1;
          setFilters((prev) => ({ ...prev, price: val != null ? +val : null }));
        }}
        inputProps={{ min: 1 }}
      />
      <div className="flex gap-3">
        <Button
          onClick={() => {
            if (Object.values(filters).find((x) => x)) {
              onSubmit(filters);
            }
          }}
          color="primary"
          variant="contained"
          className="sm:w-[172px] flex-1 sm:flex-[unset]"
          type="submit"
        >
          {dict.common.search}
        </Button>
        <Button
          onClick={() => {
            onClear();
            setFilters(defaultFilters);
          }}
          color="primary"
          variant="outlined"
          className="sm:w-[172px] flex-1 sm:flex-[unset]"
        >
          {dict.common.clear}
        </Button>
      </div>
    </div>
  );
};

export default FiltersBar;
